import NormalPhoneButton from "../sideBarPhoneButtons/NormalPhoneButton";
import CollapsePhoneButton from "../sideBarPhoneButtons/CollapsePhoneButton";
import Cookies from "universal-cookie";
const UserManagementSideBar = (props) => {
  const { handleCloseSideBar, typeOfUser, sideBarState, handleOpenSideBar } =
    props;
  const cookies = new Cookies();
  return (
    <>
      {" "}
      <NormalPhoneButton
        key={"home"}
        ButtonProperties={{
          url: "/HomeView",
          iconName: "Home",
          titleText: "Home",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalPhoneButton
        key={"User Management"}
        ButtonProperties={{
          url: "/user-management/verified-users",
          iconName: "Drivers",
          titleText: "Drivers",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      {typeOfUser === "YardOwner" && (
        <>
          <CollapsePhoneButton
            ButtonProperties={{
              iconName: "Admin",
              sideBarState: sideBarState,
              setDefaultFunction: handleCloseSideBar,
              sideBarFunction: handleOpenSideBar,
              titleText: "YMS Admin",
              childrenButtons: [
                {
                  id: "agreementList",
                  url: "/yms-admin/agreement-list",
                  titleText: "Agreement List",
                },
                {
                  id: "invoicesList",
                  url: "/yms-admin/invoices-list",
                  titleText: "Invoices List",
                },
                {
                  id: "customerList",
                  url: "/yms-admin/customer-list",
                  titleText: "Customer List",
                },
              ],
            }}
          />
          <>
            {cookies.get("sessionCosmos").email[0].includes("@snecorp.com") ? (
              <CollapsePhoneButton
                ButtonProperties={{
                  iconName: "YardOwner",
                  sideBarState: sideBarState,
                  setDefaultFunction: handleCloseSideBar,
                  sideBarFunction: handleOpenSideBar,
                  titleText: "Yard Owner",
                  childrenButtons: [
                    {
                      id: "yardOwnerList",
                      url: "/yms-admin/yard-owner-list",
                      titleText: "Owners",
                    },
                    {
                      id: "ownerYards",
                      url: "/yms-admin/owner-yards",
                      titleText: "Yards",
                    },
                  ],
                }}
              />
            ) : (
              <NormalPhoneButton
                key={"ownerYards"}
                ButtonProperties={{
                  url: "/yms-admin/owner-yards",
                  iconName: "YardOwner",
                  titleText: "Yards",
                  functionDefault: handleCloseSideBar,
                }}
              />
            )}
          </>
        </>
      )}
    </>
  );
};
export default UserManagementSideBar;
