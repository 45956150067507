import NormalButton from "../sideBarButtons/NormalButton";

const LandingSideBar = (props) => {
  const { handleCloseSideBar } = props;
  return (
    <>
      {" "}
      <NormalButton
        ButtonProperties={{
          id: "home",
          url: "/HomeView",
          iconName: "Home",
          titleText: "Home",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
    </>
  );
};
export default LandingSideBar;
