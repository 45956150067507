import "../sideBar.css";
import circularIcon from "../../assets/Logo3.png";
const ClosedSideBarLogo = (props) => {
  const { sideBarState, handleOpenSideBar } = props;

  return (
    <>
      <img
        className={
          sideBarState
            ? "circular-logo-icon-opened"
            : "circular-logo-icon-closed"
        }
        src={circularIcon}
        onClick={() => handleOpenSideBar()}
        alt="Supra Logo"
        id="circularIcon"
      />
    </>
  );
};

export default ClosedSideBarLogo;
