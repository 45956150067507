import * as React from "react";
import "./sideBar.css";
import { useSideBarContext } from "../store/sideBarcontext";

import OpenedSideBarLogo from "./sideBarLogos/openedSideBarLogo";
import ClosedSideBarLogo from "./sideBarLogos/closedSideBarLogo";
import YmsPortalSideBar from "./desktopSideBars/ymsPortalSideBar";
import YmsCarrierPortalSideBar from "./desktopSideBars/ymsCarrierPortalSideBar";
import UserManagementSideBar from "./desktopSideBars/ymsUserManagementSideBar";
import LandingSideBar from "./desktopSideBars/landingSideBar";
import SideBarPhone from "./sideBarPhone";
import { List } from "@mui/material";
import { RiPushpinFill, RiUnpinFill } from "react-icons/ri";
const SideBar = (props) => {
  const {
    isLogged,
    windowWidth,
    sideBarState,
    sideBarRefference,
    portal,
    handleOpenSideBar,
    handleCloseSideBar,
    typeOfUser,
    handlePinSideBar,
    sideBarPined,
  } = useSideBarContext();
  //
  return (
    <>
      {isLogged ? (
        <>
          {windowWidth > 900 ? (
            <>
              <section
                ref={sideBarRefference}
                id={sideBarState ? "openedSideBar" : "closedSideBar"}
              >
                {/* Logo */}

                {sideBarState ? (
                  <div className="openedSideBarLogo">
                    <OpenedSideBarLogo
                      sideBarState={sideBarState}
                      handleCloseSideBar={handleCloseSideBar}
                    />
                  </div>
                ) : (
                  <ClosedSideBarLogo
                    sideBarState={sideBarState}
                    handleOpenSideBar={handleOpenSideBar}
                  />
                )}

                <List
                  sx={{
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                    paddingTop: "52px",
                    height: "80%",
                    marginLeft: "5%",
                  }}
                  component="nav"
                >
                  <>
                    {portal === "yms-portal" && (
                      <YmsPortalSideBar
                        sideBarState={sideBarState}
                        handleCloseSideBar={handleCloseSideBar}
                        handleOpenSideBar={handleOpenSideBar}
                        handlePinSideBar={handlePinSideBar}
                        sideBarPined={sideBarPined}
                      />
                    )}
                    {portal === "yms-carrier-portal" && (
                      <YmsCarrierPortalSideBar
                        handleCloseSideBar={handleCloseSideBar}
                      />
                    )}
                    {portal === "landing" && (
                      <LandingSideBar handleCloseSideBar={handleCloseSideBar} />
                    )}
                    {portal === "user-management" && (
                      <UserManagementSideBar
                        sideBarState={sideBarState}
                        handleCloseSideBar={handleCloseSideBar}
                        handleOpenSideBar={handleOpenSideBar}
                        typeOfUser={typeOfUser}
                      />
                    )}
                  </>
                  {!sideBarPined ? (
                    <RiPushpinFill
                      onClick={() => handlePinSideBar(true)}
                      className="SideBar-pin-button"
                    ></RiPushpinFill>
                  ) : (
                    <RiUnpinFill
                      onClick={() => handlePinSideBar(false)}
                      className="SideBar-pin-button"
                    ></RiUnpinFill>
                  )}
                </List>
              </section>{" "}
            </>
          ) : (
            <SideBarPhone />
          )}
        </>
      ) : null}
    </>
  );
};
export default SideBar;
