import * as React from "react";
import SideBar from "./components/sideBar";
import "./App.css";
import { SideBarProvider, useSideBarContext } from "./store/sideBarcontext";

export default function Root(props) {
  return (
    <SideBarProvider>
      <RootContent {...props} />
    </SideBarProvider>
  );
}

function RootContent(props) {
  const { sideBarState } = useSideBarContext();

  return (
    <section id={sideBarState ? "LeftSideBarPosition" : "LeftSideBarPositionZero"}>
      <SideBar />
      {props.children}
    </section>
  );
}
