// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 900px) {
  #LeftSideBarPosition {
    position: fixed;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    transition: all 0.5s;
    z-index: 32;
    display: flex;
    width: 60%;
  }

  #LeftSideBarPositionZero {
    position: fixed;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    transition: all 0.5s;
    z-index: 32;
    display: flex;
    width: 0%;
  }
}

@media screen and (min-width: 900px) {
  #LeftSideBarPosition {
    position: fixed;
    top: 50%;
    position: fixed;
    height: 100%;
    left: 0;
    transform: translate(0, -50%);
    z-index: 32;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  #LeftSideBarPositionZero {
    position: fixed;
    top: 50%;
    position: fixed;
    height: 100%;
    left: 0;
    transform: translate(0, -50%);
    z-index: 32;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,OAAO;IACP,6BAA6B;IAC7B,oBAAoB;IACpB,WAAW;IACX,aAAa;IACb,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,OAAO;IACP,6BAA6B;IAC7B,oBAAoB;IACpB,WAAW;IACX,aAAa;IACb,SAAS;EACX;AACF;;AAEA;EACE;IACE,eAAe;IACf,QAAQ;IACR,eAAe;IACf,YAAY;IACZ,OAAO;IACP,6BAA6B;IAC7B,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,QAAQ;IACR,eAAe;IACf,YAAY;IACZ,OAAO;IACP,6BAA6B;IAC7B,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;EACzB;AACF","sourcesContent":["@media screen and (max-width: 900px) {\n  #LeftSideBarPosition {\n    position: fixed;\n    height: 100%;\n    top: 50%;\n    left: 0;\n    transform: translate(0, -50%);\n    transition: all 0.5s;\n    z-index: 32;\n    display: flex;\n    width: 60%;\n  }\n\n  #LeftSideBarPositionZero {\n    position: fixed;\n    height: 100%;\n    top: 50%;\n    left: 0;\n    transform: translate(0, -50%);\n    transition: all 0.5s;\n    z-index: 32;\n    display: flex;\n    width: 0%;\n  }\n}\n\n@media screen and (min-width: 900px) {\n  #LeftSideBarPosition {\n    position: fixed;\n    top: 50%;\n    position: fixed;\n    height: 100%;\n    left: 0;\n    transform: translate(0, -50%);\n    z-index: 32;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: white;\n  }\n\n  #LeftSideBarPositionZero {\n    position: fixed;\n    top: 50%;\n    position: fixed;\n    height: 100%;\n    left: 0;\n    transform: translate(0, -50%);\n    z-index: 32;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: white;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
