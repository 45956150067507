import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import "../sideBar.css";
import { useSideBarContext } from "../../store/sideBarcontext";
const NormalButton = (props) => {
  const { ButtonProperties } = props;
  const { currentLocation, handleSetCurrentLocation } = useSideBarContext();

  function turnToActive() {
    handleSetCurrentLocation(ButtonProperties.url);
  }

  function pushNewUrl() {
    // You can add logic here to ask validations of the session to activate the button
    window.history.pushState({}, null, ButtonProperties.url);
  }

  function handleClick(e) {
    ButtonProperties.functionDefault();
    turnToActive();
    pushNewUrl();
  }

  return (
    <>
      <ListItemButton
        id={ButtonProperties.id}
        className={
          currentLocation === ButtonProperties.url
            ? "listItemButtonSelected"
            : "listItemButton"
        }
        onClick={(e) => handleClick()}
      >
        <ListItemIcon style={{ minWidth: "42px" }}>
          <img
            src={
              process.env.REACT_APP_STATIC_IMG_URL +
              (currentLocation === ButtonProperties.url
                ? `${ButtonProperties.iconName} Blanco.svg`
                : `${ButtonProperties.iconName}.svg`)
            }
            alt=""
            style={{
              maxWidth: "25px",
              maxHeight: "25px",
              paddingRight: "3px",
              scale: "1.3",
            }}
          />
        </ListItemIcon>
        <ListItemText primary={ButtonProperties.titleText} />
      </ListItemButton>
    </>
  );
};

export default NormalButton;
