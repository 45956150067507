// eslint-disable prettier/prettier

import * as React from "react";
import "./sideBar.css";
import { useSideBarContext } from "../store/sideBarcontext";
import { List } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import YmsPortalSideBar from "./phoneSideBars/ymsPortalSideBar";
import YmsCarrierPortalSideBar from "./phoneSideBars/ymsCarrierPortalSideBar";
import UserManagementSideBar from "./phoneSideBars/ymsUserManagementSideBar";
import LandingSideBar from "./phoneSideBars/landingSideBar";
import OpenedSideBarLogo from "./sideBarLogos/openedSideBarLogo";
import ClosedSideBarLogo from "./sideBarLogos/closedSideBarLogo";

const sideBarPhone = () => {
  const {
    sideBarState,
    sideBarPhoneRefference,
    portal,
    handleOpenSideBar,
    handleCloseSideBar,
    typeOfUser,
  } = useSideBarContext();

  return (
    <>
      <section
        ref={sideBarPhoneRefference}
        id={sideBarState ? "openedSideBar" : "closedSideBar"}
      >
        {sideBarState ? (
          <div className="openedSideBarLogo">
            <OpenedSideBarLogo
              sideBarState={sideBarState}
              handleCloseSideBar={handleCloseSideBar}
            />
          </div>
        ) : (
          <ClosedSideBarLogo
            sideBarState={sideBarState}
            handleOpenSideBar={handleOpenSideBar}
          />
        )}

        <List
          sx={{
            width: "90%",
            bgcolor: "background.paper",
            paddingTop: "52px",
            height: "80%",
            marginLeft: "5%",
          }}
          component="nav"
        >
          <>
            {portal === "yms-portal" && (
              <YmsPortalSideBar
                sideBarState={sideBarState}
                handleCloseSideBar={handleCloseSideBar}
                handleOpenSideBar={handleOpenSideBar}
              />
            )}
            {portal === "yms-carrier-portal" && (
              <YmsCarrierPortalSideBar
                handleCloseSideBar={handleCloseSideBar}
              />
            )}
            {portal === "landing" && (
              <LandingSideBar handleCloseSideBar={handleCloseSideBar} />
            )}
            {portal === "user-management" && (
              <UserManagementSideBar
                sideBarState={sideBarState}
                handleCloseSideBar={handleCloseSideBar}
                handleOpenSideBar={handleOpenSideBar}
                typeOfUser={typeOfUser}
              />
            )}
          </>
        </List>
      </section>
      {sideBarState ? (
        <CloseIcon
          style={{ marginTop: "3%", marginLeft: "1%", scale: "1.2" }}
          onClick={() => {
            handleCloseSideBar();
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default sideBarPhone;
