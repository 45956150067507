import NormalButton from "../sideBarButtons/NormalButton";

const YmsCarrierPortalSideBar = (props) => {
  const { handleCloseSideBar } = props;
  return (
    <>
      {" "}
      <NormalButton
        ButtonProperties={{
          id: "home",
          url: "/HomeView",
          iconName: "Home",
          titleText: "Home",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalButton
        ButtonProperties={{
          id: "inoutgate",
          url: "/yms-carrier-portal/checkinout",
          iconName: "In-Out Gate",
          titleText: "In/Out Gate",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalButton
        ButtonProperties={{
          id: "inyardstorage",
          url: "/yms-carrier-portal/inyardstorage",
          iconName: "Yard Storage",
          titleText: "Yard Storage",
          functionDefault: handleCloseSideBar,
        }}
      />
      <NormalButton
        ButtonProperties={{
          id: "conHistory",
          url: "/yms-carrier-portal/containershistory",
          iconName: "cont. History",
          titleText: "Cont. History",
          functionDefault: handleCloseSideBar,
        }}
      />
      <NormalButton
        ButtonProperties={{
          id: "drivers",
          url: "/yms-carrier-portal/drivers",
          iconName: "Drivers",
          titleText: "Drivers",
          functionDefault: handleCloseSideBar,
        }}
      />
    </>
  );
};
export default YmsCarrierPortalSideBar;
