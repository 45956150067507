import { useSideBarContext } from "../../store/sideBarcontext";
import React from "react";
import "../sideBar.css";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
const CollapsePhoneButton = (props) => {
  const { currentLocation, handleSetCurrentLocation } = useSideBarContext();

  const [collapsePhoneButtonState, setCollapsePhoneButtonState] =
    React.useState(false);

  const { ButtonProperties } = props;

  function functionOpenClose() {
    setCollapsePhoneButtonState(!collapsePhoneButtonState);
    if (!collapsePhoneButtonState) {
      ButtonProperties.sideBarFunction();
    }
  }

  function turnToActive(url) {
    handleSetCurrentLocation(url);
  }

  function pushNewUrl(url) {
    window.history.pushState({}, null, url);
  }

  function handleClick(UrlOfChildrenButton) {
    ButtonProperties.setDefaultFunction();
    turnToActive(UrlOfChildrenButton);
    pushNewUrl(UrlOfChildrenButton);
  }

  React.useEffect(() => {
    if (!ButtonProperties.sideBarState) {
      setCollapsePhoneButtonState(false);
    }
  }, [ButtonProperties.sideBarState]);

  return (
    <>
      <ListItemButton
        onClick={functionOpenClose}
        className="collapse-list-item-button"
        id={
          collapsePhoneButtonState ||
          ButtonProperties.childrenButtons.some(
            (button) => button.url === currentLocation
          )
            ? "buttonActivated"
            : "collapseNotActivated"
        }
      >
        <ListItemIcon
          style={{
            minWidth: "35px",
            ...(collapsePhoneButtonState ? { color: "white" } : {}),
          }}
        >
          <img
            src={
              process.env.REACT_APP_STATIC_IMG_URL +
              (collapsePhoneButtonState ||
              ButtonProperties.childrenButtons.some(
                (button) => button.url === currentLocation
              )
                ? `${ButtonProperties.iconName} Blanco.svg`
                : `${ButtonProperties.iconName}.svg`)
            }
            alt=""
            className="sidebar-phone-icon"
          />
        </ListItemIcon>
        <ListItemText primary={ButtonProperties.titleText} />
        {collapsePhoneButtonState ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div
        style={{
          ...(collapsePhoneButtonState ? { marginBottom: "10px" } : {}),
        }}
      >
        <Collapse
          in={collapsePhoneButtonState}
          timeout="auto"
          unmountOnExit
          className="collapse-list-children-phone"
        >
          <List component="div" disablePadding>
            {ButtonProperties.childrenButtons.map((button) => (
              <ListItemButton
                key={button.id}
                id="ListItemButtonCollapse"
                onClick={(e) => handleClick(button.url)}
              >
                <ListItemText
                  style={{
                    fontSize: "18px",
                    color: currentLocation === button.url ? "#fc4818" : "",
                  }}
                  primary={button.titleText}
                />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </div>
    </>
  );
};

export default CollapsePhoneButton;
