import NormalPhoneButton from "../sideBarPhoneButtons/NormalPhoneButton";

const YmsCarrierPortalSideBar = (props) => {
  const { handleCloseSideBar } = props;
  return (
    <>
      {" "}
      <NormalPhoneButton
        key={"home"}
        ButtonProperties={{
          url: "/HomeView",
          iconName: "Home",
          titleText: "Home",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalPhoneButton
        key={"inoutgate"}
        ButtonProperties={{
          url: "/yms-carrier-portal/checkinout",
          iconName: "In-Out Gate",
          titleText: "In/Out Gate",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalPhoneButton
        key={"inyardstorage"}
        ButtonProperties={{
          url: "/yms-carrier-portal/inyardstorage",
          iconName: "Yard Storage",
          titleText: "Yard Storage",
          functionDefault: handleCloseSideBar,
        }}
      />
      <NormalPhoneButton
        key={"conHistory"}
        ButtonProperties={{
          url: "/yms-carrier-portal/containershistory",
          iconName: "cont. History",
          titleText: "Cont. History",
          functionDefault: handleCloseSideBar,
        }}
      />
      <NormalPhoneButton
        key={"drivers"}
        ButtonProperties={{
          url: "/yms-carrier-portal/drivers",
          iconName: "Drivers",
          titleText: "Drivers",
          functionDefault: handleCloseSideBar,
        }}
      />
    </>
  );
};
export default YmsCarrierPortalSideBar;
