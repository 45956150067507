import NormalPhoneButton from "../sideBarPhoneButtons/NormalPhoneButton";

const LandingSideBar = (props) => {
  const { handleCloseSideBar } = props;
  return (
    <>
      <NormalPhoneButton
        key={"home"}
        ButtonProperties={{
          url: "/HomeView",
          iconName: "Home",
          titleText: "Home",
          functionDefault: handleCloseSideBar,
        }}
      />
    </>
  );
};
export default LandingSideBar;
