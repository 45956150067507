import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSideBarContext } from "../../store/sideBarcontext";
import React from "react";
import "../sideBar.css";

const CollapseButton = (props) => {
  const { currentLocation, handleSetCurrentLocation } = useSideBarContext();
  const [collapseLocalStatement, setCollapseLocalStatement] =
    React.useState(false);
  const {
    childrenButtons,
    icon,
    sideBarState,
    setDefaultFunction,
    title,
    sideBarFunction,
  } = props;

  function functionOpenClose() {
    setCollapseLocalStatement(!collapseLocalStatement);
    if (!collapseLocalStatement) {
      sideBarFunction();
    }
  }

  function turnToActive(url) {
    handleSetCurrentLocation(url);
  }

  function pushNewUrl(url) {
    window.history.pushState({}, null, url);
  }

  function handleClick(UrlOfChildrenButton) {
    setDefaultFunction();
    turnToActive(UrlOfChildrenButton);
    pushNewUrl(UrlOfChildrenButton);
  }

  React.useEffect(() => {
    if (!sideBarState) {
      setCollapseLocalStatement(false);
    }
  }, [sideBarState]);

  return (
    <>
      <ListItemButton
        onClick={functionOpenClose}
        className={
          collapseLocalStatement ||
          childrenButtons.some((button) => button.url === currentLocation)
            ? "buttonCollapseActivated listItemButton"
            : "collapseNotActivated listItemButton"
        }
      >
        <ListItemIcon
          style={{
            minWidth: "42px",
            ...(collapseLocalStatement ? { color: "white" } : {}),
          }}
        >
          <img
            src={
              process.env.REACT_APP_STATIC_IMG_URL +
              (collapseLocalStatement ||
              childrenButtons.some((button) => button.url === currentLocation)
                ? `${icon} Blanco.svg`
                : `${icon}.svg`)
            }
            alt=""
            id="imageCollapseButton"
          />
        </ListItemIcon>
        <ListItemText primary={title} />
        {collapseLocalStatement ? (
          <ExpandLess
            id={`expandButton`}
            onClick={() => {
              functionOpenClose;
            }}
          />
        ) : (
          <ExpandMore
            id="expandButton"
            onClick={() => {
              functionOpenClose;
            }}
          />
        )}
      </ListItemButton>
      <div
        style={{ ...(collapseLocalStatement ? { marginBottom: "10px" } : {}) }}
      >
        <Collapse
          in={collapseLocalStatement}
          timeout="auto"
          unmountOnExit
          id="ItemCollapse"
        >
          <List component="div" disablePadding>
            {childrenButtons.map((button) => (
              <ListItemButton
                key={button.id}
                id="ListItemButtonCollapse"
                onClick={(e) => handleClick(button.url)}
              >
                <ListItemText
                  style={{
                    fontSize: "18px",
                    color: currentLocation === button.url ? "#fc4818" : "",
                  }}
                  primary={button.titleText}
                />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </div>
    </>
  );
};

export default CollapseButton;
