import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useSideBarContext } from "../../store/sideBarcontext";
import React from "react";
const NormalPhoneButton = (props) => {
  //PROPERTIES----------------------------------------------------------

  const { ButtonProperties } = props;
  const { currentLocation, handleSetCurrentLocation } = useSideBarContext();

  //FUNCTIONS----------------------------------------------------------
  function turnToActive() {
    handleSetCurrentLocation(ButtonProperties.url);
  }

  function pushNewUrl() {
    // You can add logic here to ask validations of the session to activate the button
    window.history.pushState({}, null, ButtonProperties.url);
  }

  function handleClick(e) {
    ButtonProperties.functionDefault();
    turnToActive();
    pushNewUrl();
  }

  return (
    <ListItemButton
      className={
        currentLocation === ButtonProperties.url
          ? "listItemButtonSelected"
          : "listItemButton"
      }
      onClick={() => handleClick()}
    >
      <ListItemIcon style={{ minWidth: "25px" }}>
        <img
          src={
            process.env.REACT_APP_STATIC_IMG_URL +
            (currentLocation === ButtonProperties.url
              ? `${ButtonProperties.iconName} Blanco.svg`
              : `${ButtonProperties.iconName}.svg`)
          }
          alt=""
          className="sidebar-phone-icon"
        />
      </ListItemIcon>
      <ListItemText primary={ButtonProperties.titleText} />
    </ListItemButton>
  );
};

export default NormalPhoneButton;
