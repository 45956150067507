import * as React from "react";
const SideBarContext = React.createContext();
export const useSideBarContext = () => React.useContext(SideBarContext);
import Cookies from "universal-cookie";
export const SideBarProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = React.useState(
    window.location.pathname
  );
  const cookies = new Cookies();
  const [sideBarState, setSideBarState] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const sideBarRefference = React.useRef(null);
  const sideBarPhoneRefference = React.useRef(null);
  const [isLogged, setIsLogged] = React.useState(false);
  const [portal, setPortal] = React.useState("");
  const [typeOfUser, setTypeOfUser] = React.useState("");
  const [sideBarPined, setSideBarPined] = React.useState(false);
  //Load to blobStorage if you need to change the buttons without deploying each time

  //EFFECTS----------------------------------------------------------

  React.useEffect(() => {
    handleCheckPortal();
    handleSetRoutePaddingLeft();
    handleCheckIsLoggedIn();
  }, [sideBarRefference]);
  // document.addEventListener("click", handleClickOutside);
  window.addEventListener("resize", () => {
    handleResize();
    handleSetRoutePaddingLeft();
  });
  window.addEventListener("popstate", () => {
    handleCheckPortal();
    handleSetCurrentLocation(window.location.pathname);
    handleSetRoutePaddingLeft();
    handleCheckIsLoggedIn();
    if (localStorage.getItem("sideBarPined") === "true") {
      handlePinSideBar();
    }
  });
  window.addEventListener("UserDataLoaded", () => {
    handleSetRoutePaddingLeft();
    handleCheckIsLoggedIn();
  });
  window.addEventListener("logOut", () => {
    handlePinSideBar(false);
  });
  window.addEventListener("landingPageOpen", () => {
    handlePinSideBar(false);
  });
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener(
      "openSideBarPhone",
      () => {
        handleOpenSideBar();
      },
      []
    );
    return () => {};
  }, [sideBarPhoneRefference]);

  //FUNCTIONS----------------------------------------------------------

  function handleCheckIsLoggedIn() {
    const currentUrl = window.location.href;
    const baseUrl = window.location.origin + "/";
    if (
      cookies.get("sessionCosmos") &&
      ((cookies.get("sessionCosmos").type === "YardOwner" &&
        cookies.get("sessionCosmos").YardOwnerStatus === "Enabled") ||
        cookies.get("sessionCosmos").type !== "YardOwner") &&
      !window.location.href.includes("create-user") &&
      !window.location.href.includes("create-yard-owner") &&
      !window.location.href.includes("landing-page/home") &&
      currentUrl !== baseUrl
    ) {
      setTypeOfUser(cookies.get("sessionCosmos").type);
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }

  function handleSetRoutePaddingLeft() {
    const pinned = document.querySelector(".route-padding-left-pined");
    if (pinned) return;
    const route = document.querySelector(".route-padding-left");
    if (
      route &&
      cookies.get("sessionCosmos") &&
      ((cookies.get("sessionCosmos").type === "YardOwner" &&
        cookies.get("sessionCosmos").YardOwnerStatus === "Enabled") ||
        cookies.get("sessionCosmos").type !== "YardOwner") &&
      !window.location.href.includes("create-user") &&
      !window.location.href.includes("landing-page/home") &&
      !window.location.href.includes("create-yard-owner")
    ) {
      route.classList.add("route-padding-left-activated");
    } else {
      if (route) route.classList.remove("route-padding-left-activated");
    }
  }

  function handleSetCurrentLocation(url) {
    setCurrentLocation(url);
  }

  function handleCheckPortal() {
    const location = window.location.href;

    if (
      location.includes("yms-portal") ||
      location.includes("yms-st") ||
      location.includes("yms-chassis-portal")
    ) {
      setPortal("yms-portal");
    } else if (location.includes("yms-carrier-portal")) {
      setPortal("yms-carrier-portal");
    } else if (
      location.includes("user-management") ||
      location.includes("yms-admin")
    ) {
      setPortal("user-management");
    } else if (location.includes("landing") || location.includes("HomeView")) {
      setPortal("landing");
    }
  }

  function handleOpenSideBar() {
    if (sideBarPined) return;
    setSideBarState(true);
  }

  function handleCloseSideBar() {
    if (sideBarPined) return;
    setSideBarState(false);
  }

  function handleResize() {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth < 900) {
      handlePinSideBar(false);
    }
  }

  function handleOpenCloseCollapseButton() {
    if (sideBarState) {
    } else {
      setSideBarState(true);
    }
  }

  function handleClickOutside(event) {
    if (
      sideBarRefference.current &&
      !sideBarRefference.current.contains(event.target) &&
      !isWithinExpandButtons(event, ["expandButton"]) &&
      !event.target.closest("#circularIcon") &&
      !event.target.closest("#ymsHorizontalHover")
    ) {
      setSideBarState(false);
    }
  }

  function isWithinExpandButtons(event, buttonIds) {
    for (const id of buttonIds) {
      const expandButton = document.getElementById(id);
      if (expandButton) {
        const expandButtonRect = expandButton.getBoundingClientRect();
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        if (
          mouseX >= expandButtonRect.left &&
          mouseX <= expandButtonRect.right &&
          mouseY >= expandButtonRect.top &&
          mouseY <= expandButtonRect.bottom
        ) {
          return true;
        }
      }
    }
    return false;
  }

  function pinSideBar(side, navbar) {
    side.classList.add("route-padding-left-pined");
    side.classList.remove("route-padding-left-activated");
    navbar.classList.add("navbar-sidebar-pinned");
    navbar.classList.remove("navbar-static-top");
    setSideBarPined(true);
    setSideBarState(true);
    localStorage.setItem("sideBarPined", true);
  }
  function unpinSideBar(side, navbar) {
    side.classList.add("route-padding-left-activated");
    side.classList.remove("route-padding-left-pined");
    navbar.classList.add("navbar-static-top");
    navbar.classList.remove("navbar-sidebar-pinned");
    setSideBarPined(false);
    setSideBarState(false);
    localStorage.setItem("sideBarPined", false);
  }

  function handlePinSideBar(action) {
    if (
      (!sideBarPined && localStorage.getItem("sideBarPined") === "true") ||
      action ||
      (action === undefined && sideBarPined)
    ) {
      const side = document.querySelector(".route-padding-left");
      const navbar = document.getElementById("yms-navbar");
      if (!side || !navbar) return;
      pinSideBar(side, navbar);
    } else {
      const side = document.querySelector(".route-padding-left-pined");
      const navbar = document.getElementById("yms-navbar");
      if (!side || !navbar) return;
      unpinSideBar(side, navbar);
    }
  }

  React.useEffect(() => {}, []);

  return (
    <SideBarContext.Provider
      value={{
        isLogged,
        currentLocation,
        sideBarState,
        windowWidth,
        sideBarRefference,
        sideBarPhoneRefference,
        portal,
        typeOfUser,
        sideBarPined,
        handleOpenCloseCollapseButton,
        handleClickOutside,
        handleOpenSideBar,
        handleCloseSideBar,
        handleSetCurrentLocation,
        handlePinSideBar,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};
