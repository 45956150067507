import NormalButton from "../sideBarButtons/NormalButton";
import CollapseButton from "../sideBarButtons/CollapseButton";

const YmsPortalSideBar = (props) => {
  const { sideBarState, handleCloseSideBar, handleOpenSideBar } = props;
  return (
    <>
      {" "}
      <NormalButton
        ButtonProperties={{
          id: "home",
          url: "/HomeView",
          iconName: "Home",
          titleText: "Home",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalButton
        ButtonProperties={{
          id: "inoutgate",
          url: "/yms-portal/checkinout",
          iconName: "In-Out Gate",
          titleText: "In/Out Gate",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalButton
        ButtonProperties={{
          id: "pending",
          url: "/yms-portal/pending",
          iconName: "Pending",
          titleText: "Pending",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalButton
        ButtonProperties={{
          id: "gatelog",
          url: "/yms-portal/gatelog",
          iconName: "Gate Log",
          titleText: "Gate Log",
          functionDefault: handleCloseSideBar,
        }}
      />
      <NormalButton
        ButtonProperties={{
          id: "inyardstorage",
          url: "/yms-portal/inyardstorage",
          iconName: "Yard Storage",
          titleText: "Yard Storage",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalButton
        ButtonProperties={{
          id: "chassisList",
          url: "/yms-chassis-portal/chassis-rented",
          iconName: "ChassisSolo",
          titleText: "Chassis Rented List",
          functionDefault: handleCloseSideBar,
        }}
      />
      <CollapseButton
        id="yardTasks"
        icon="Flip"
        sideBarState={sideBarState}
        setDefaultFunction={handleCloseSideBar}
        sideBarFunction={handleOpenSideBar}
        title="Yard Tasks"
        childrenButtons={[
          {
            id: "ymsFlips",
            url: "/yms-st/pending-flips",
            titleText: "Pending Flips",
          },
          {
            id: "pendingInspect",
            url: "/yms-st/pending-inspect",
            titleText: "Pending Inspect",
          },
        ]}
      />
      <CollapseButton
        id="streetTurn"
        icon="Street Turn"
        sideBarState={sideBarState}
        setDefaultFunction={handleCloseSideBar}
        sideBarFunction={handleOpenSideBar}
        title="Empties"
        childrenButtons={[
          {
            id: "streetTurnSummary",
            url: "/yms-st/streetturn-sslsummary",
            titleText: "SSL Summary",
          },
          {
            id: "streetTurnPending",
            url: "/yms-st/streetturn-sslsummarydetails/?sslScac=ALL",
            titleText: "Available Empties",
          },
          {
            id: "stPEnding",
            url: "/yms-st/sreetturnspending",
            titleText: "Empties For Export",
          },
        ]}
      />{" "}
      <CollapseButton
        id="historys"
        icon="cont. History"
        sideBarState={sideBarState}
        setDefaultFunction={handleCloseSideBar}
        sideBarFunction={handleOpenSideBar}
        title="History"
        childrenButtons={[
          {
            id: "conHistory",
            url: "/yms-portal/containershistory",
            titleText: "Cont. History",
          },
          {
            id: "chassisHistory",
            url: "/yms-chassis-portal/chassis-history",
            titleText: "Chassis History",
          },
        ]}
      />
      <NormalButton
        ButtonProperties={{
          id: "drivers",
          url: "/yms-portal/drivers",
          iconName: "Drivers",
          titleText: "Drivers",
          functionDefault: handleCloseSideBar,
        }}
      />
    </>
  );
};
export default YmsPortalSideBar;
