import NormalButton from "../sideBarButtons/NormalButton";
import CollapseButton from "../sideBarButtons/CollapseButton";
import Cookies from "universal-cookie";
const UserManagementSideBar = (props) => {
  const { handleCloseSideBar, typeOfUser, sideBarState, handleOpenSideBar } =
    props;
  const cookies = new Cookies();
  return (
    <>
      {" "}
      <NormalButton
        ButtonProperties={{
          id: "home",
          url: "/HomeView",
          iconName: "Home",
          titleText: "Home",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalButton
        ButtonProperties={{
          id: "User Management",
          url: "/user-management/verified-users",
          iconName: "Drivers",
          titleText: "Drivers",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      {typeOfUser === "YardOwner" && (
        <>
          {" "}
          <CollapseButton
            id="ymsAdmin"
            icon="Admin"
            sideBarState={sideBarState}
            setDefaultFunction={handleCloseSideBar}
            sideBarFunction={handleOpenSideBar}
            title="YMS Admin"
            childrenButtons={[
              {
                id: "agreementList",
                url: "/yms-admin/agreement-list",
                titleText: "Agreement List",
              },
              {
                id: "invoicesList",
                url: "/yms-admin/invoices-list",
                titleText: "Invoices List",
              },
              {
                id: "customerList",
                url: "/yms-admin/customer-list",
                titleText: "Customer List",
              },
            ]}
          />
          {cookies.get("sessionCosmos").email[0].includes("@snecorp.com") ? (
            <CollapseButton
              id="yardOwner"
              icon="YardOwner"
              sideBarState={sideBarState}
              setDefaultFunction={handleCloseSideBar}
              sideBarFunction={handleOpenSideBar}
              title="Yard Owner"
              childrenButtons={[
                {
                  id: "yardOwnerList",
                  url: "/yms-admin/yard-owner-list",
                  titleText: "Owners",
                },
                {
                  id: "ownerYards",
                  url: "/yms-admin/owner-yards",
                  titleText: "Yards",
                },
              ]}
            />
          ) : (
            <NormalButton
              key={"ownerYards"}
              ButtonProperties={{
                url: "/yms-admin/owner-yards",
                iconName: "YardOwner",
                titleText: "Yards",
                functionDefault: handleCloseSideBar,
              }}
            />
          )}
        </>
      )}
    </>
  );
};
export default UserManagementSideBar;
